import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { bsc } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const projectId = "88d357fdad12b22b5bd0cca51dd3a1e5";

const metadata = {
  name: "Dragonline",
  description: "Dragonline",
  url: "https://dragonline.io",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc] as const;
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  //...wagmiOptions
});

createWeb3Modal({
  tokens: {
    56: {
      address: '0x55d398326f99059fF775485246999027B3197955',
      image: 'https://etherscan.io/token/images/tethernew_32.png'
    },
  },
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  themeVariables: {
    '--w3m-color-mix': '#132f2e',
    '--w3m-color-mix-strength': 0,
    '--w3m-accent': ' #c9ab5d',
    '--w3m-z-index': 99999,
  }
});

export function Web3ModalProvider({ children }: any) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}

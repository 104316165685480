import { useEffect, useState } from "react";
import styles from "./PackageList.module.css";
import {
  useWriteContract,
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import { wagmiUSDTConfig } from "../../contracts/USDT";
import { wagmiContractConfig } from "../../contracts/contract";
import { ethers } from "ethers";
import urls from "../../urls.json";
import axios from "axios";

const PackageList = ({ packages, userId, wallet }) => {
  const [isSending, setIsSending] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data: dataAllowance } = useReadContract({
    ...wagmiUSDTConfig,
    functionName: "allowance",
    args: [address, wagmiContractConfig.address],
  });

  useEffect(() => {
    if (dataAllowance) {
      setAllowance(
        parseFloat(ethers.utils.formatUnits(dataAllowance.toString(), "ether"))
      );
    }
  }, [dataAllowance]);

  useEffect(() => {
    if (isPending && !isConfirmed) {
      setIsSending(true);
    } else if (isConfirmed) {
      setIsSending(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [isConfirmed, isPending]);

  const buyPackages = async (packageId) => {
    try {
      writeContract({
        ...wagmiContractConfig,
        functionName: "buyPackage",
        args: [userId, packageId],
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const approveUSDT = async (packageId) => {
    try {
      const selectedPackage = packages.find((x) => x.id === packageId);
      const amount = ethers.utils.parseUnits(
        selectedPackage.price.toString(),
        18
      );
      writeContract({
        ...wagmiUSDTConfig,
        functionName: "approve",
        args: [wagmiContractConfig.address, amount],
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div data-aos="fade-right" className={styles.package_container}>
      {packages.map((x, index) => (
        <div
          key={index}
          className={styles.package}
          visibility={x.purchasable ? null : "unavailable"}
        >
          <img src={x.imageUrl} alt={x.name} />
          <div className={styles.packageInfo}>
            <h3>{x.name}</h3>
            <p className="packagePrice">$ {x.price}</p>
          </div>
          {userId != null && wallet != null && wallet !== "" && (
            <>
              {allowance >= x.price ? (
                <button
                  onClick={async () => await buyPackages(x.id)}
                  disabled={!x.purchasable || isSending}
                  className={x.purchasable ? null : styles.unavailable}
                >
                  {x.purchasable ? "Purchase" : "Unavailable"}
                </button>
              ) : (
                <button
                  onClick={async () => await approveUSDT(x.id)}
                  disabled={!x.purchasable || isSending}
                  className={x.purchasable ? null : styles.unavailable}
                >
                  {x.purchasable ? "Select Package" : "Unavailable"}
                </button>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PackageList;

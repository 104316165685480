import React, { useState } from "react";
import styles from "./transactions.module.css";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import axios from "axios";
import urls from "../urls.json";
import { TablePagination } from "@mui/material";
import { useParams } from "react-router-dom";

export default function AdminReferrals() {
  const { user_id } = useParams();
  const token = localStorage.getItem("token");
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPage, setRowsPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRows = (event) => {
    setRowsPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async () => {
    const url =
      urls.main +
      `/api/v1/users/${user_id}/referrals?offset=${
        page * rowsPage
      }&limit=${rowsPage}`;
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setItems(response.data.items);
        setCount(response.data.count);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <div className={styles.transactions_wrapper}>
      <h1 style={{ color: "#c9ab5d" }}>Referrals of user with ID {user_id}</h1>
      <Link to="/admin-panel">
        <button className={styles.goback_btn}>Go to admin panel</button>
      </Link>{" "}
      <Link to="/admin-panel/users">
        <button className={styles.goback_btn}>Go back</button>
      </Link>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell data-aos="fade-right" align="center">
                Username
              </TableCell>
              <TableCell data-aos="fade-right" align="center">
                Name
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Surname
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Wallet
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Date registration
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell data-aos="fade-left" align="center">
                  {row.username}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.first_name}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.last_name}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.wallet}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {new Date(row.created_at).toLocaleString("ru-RU")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ color: "#fff", backgroundColor: "#c9ab5d" }}
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPage}
        onRowsPerPageChange={handleChangeRows}
      />
    </div>
  );
}

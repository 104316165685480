import styles from "./profile.module.css";
import Button from "../../components/button/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../providers/user";

export default function PersonalData() {
  const { user, loading } = useUser();
  const [t, i18n] = useTranslation();

  return (
    <div className={styles.personal_data}>
      <h2
        data-aos="fade-right"
        data-aos-delay="300"
        style={{ color: "#c9ab5d" }}
      >
        {t("profile.3")}
      </h2>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div
          style={{ marginBottom: "30px" }}
          className={styles.user_data_container}
        >
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              {t("profile.7")}
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={styles.personal_data_option_text}
              >
                {user.username}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              {t("profile.4")}
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={styles.personal_data_option_text}
              >
                {user.first_name}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              {t("profile.5")}
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={styles.personal_data_option_text}
              >
                {user.last_name}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              E-mail
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={styles.personal_data_option_text}
              >
                {user.email}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              {t("profile.8")}
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={styles.personal_data_option_text}
              >
                {new Date(user.created_at).toLocaleDateString("ru-RU")}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              {t("profile.9")}
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={styles.personal_data_option_text}
              >
                {user.first_buy_date
                  ? new Date(user.first_buy_date).toLocaleDateString("ru-RU")
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      )}
      <Link style={{ padding: "5px" }} data-aos="fade-right" to="/profile/edit">
        <Button
          icon={<FontAwesomeIcon icon={faEdit} />}
          name={t("profile.10")}
          fw={"bold"}
          border={"none"}
          bg={"#c9ab5d"}
          margin={"10px"}
        />
      </Link>

      <Link
        style={{ padding: "5px" }}
        data-aos="fade-right"
        to="/wallet-history"
      >
        <Button
          icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
          name={t("profile.wallet_history")}
          fw={"bold"}
          border={"none"}
          bg={"#c9ab5d"}
          margin={"10px"}
        />
      </Link>
      <Link
        style={{ padding: "5px" }}
        data-aos="fade-right"
        to="/update-password"
      >
        <Button
          icon={<FontAwesomeIcon icon={faKey} />}
          name={t("profile.update_password")}
          fw={"bold"}
          border={"none"}
          bg={"#c9ab5d"}
          margin={"10px"}
        />
      </Link>
      <Link
        style={{ padding: "5px" }}
        data-aos="fade-right"
        to="/configure-2fa"
      >
        <Button
          icon={<FontAwesomeIcon icon={faQrcode} />}
          name={t("2fa.configure_button")}
          fw={"bold"}
          border={"none"}
          bg={"#c9ab5d"}
          margin={"10px"}
        />
      </Link>
    </div>
  );
}

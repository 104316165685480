import { useEffect, useState } from "react";
import styles from "./profileEdit.module.css";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import ModalWindow from "../../components/modal/ModalWindow";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../providers/user";
import axios from "axios";

export default function ProfileEdit() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { user, loading } = useUser();
  const [isValidWallet, setIsValidWallet] = useState(true);

  const handleInputWallet = (e) => {
    const inputValue = e.target.value;
    setWallet(inputValue);
    if (inputValue.startsWith("0x") && inputValue.length === 42) {
      setIsValidWallet(true);
    } else {
      setIsValidWallet(false);
    }
  };

  const [errorMessage, setErrorMessage] = useState("Error");
  const [isModal, setModal] = useState(false);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [surname, setSurname] = useState("");
  const [wallet, setWallet] = useState("");
  const [defaultWallet, setDefaultWallet] = useState("");
  const [otpCode, setOtpCode] = useState("");

  const token = localStorage.getItem("token");

  const updateUser = () => {
    const options = {
      url: urls.main + "/api/v1/users/me",
      method: "PUT",
      headers: { Authorization: token },
      body: {
        username: username,
        first_name: name,
        last_name: surname,
        wallet: wallet,
        otp_code: otpCode,
      },
    };
    axios
      .put(options.url, options.body, options)
      .then((response) => {
        navigate("/profile");
        window.location.reload();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setModal(true);
      });
  };

  useEffect(() => {
    if (!loading) {
      setUsername(user.username);
      setName(user.first_name);
      setSurname(user.last_name);
      setWallet(user.wallet);
      setDefaultWallet(user.wallet);
    }
  }, [user, loading]);

  return (
    <div className={styles.edit_wrapper}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={errorMessage}
      />
      <h1 style={{ color: "#c9ab5d", textAlign: "center" }}>{t("edit.1")}</h1>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <div className={styles.edit_item}>
            <p style={{ color: "#fff" }}>{t("edit.12")}</p>
            <div className={styles.adaptive_wrapper}>
              <input
                onInput={(e) => setUsername(e.target.value)}
                value={username}
                className={styles.edit_input}
                placeholder={t("edit.12")}
                type="text"
              />
            </div>
          </div>

          <div className={styles.edit_item}>
            <p style={{ color: "#fff" }}>{t("reg.3")}</p>
            <div className={styles.adaptive_wrapper}>
              <input
                onInput={(e) => setName(e.target.value)}
                value={name}
                className={styles.edit_input}
                placeholder={t("reg.3")}
                type="text"
              />
            </div>
          </div>

          <div className={styles.edit_item}>
            <p style={{ color: "#fff" }}>{t("reg.4")}</p>
            <div className={styles.adaptive_wrapper}>
              <input
                onInput={(e) => setSurname(e.target.value)}
                value={surname}
                className={styles.edit_input}
                placeholder={t("reg.4")}
                type="text"
              />
            </div>
          </div>
          <div className={styles.edit_item}>
            <p style={{ color: "#fff" }}>{t("reg.20")}</p>
            <div className={styles.adaptive_wrapper}>
              <input
                onInput={handleInputWallet}
                value={wallet}
                className={`${styles.input} ${
                  isValidWallet ? styles.valid : styles.invalid
                }`}
                placeholder={t("reg.20")}
                type="text"
              />
              {!isValidWallet && (
                <p className={styles.error}>{t("reg.wallet_error")}</p>
              )}
            </div>
          </div>
          {user.otp_enable && user.otp_wallet_change && wallet != defaultWallet && (
            <div className={styles.edit_item}>
              <p style={{ color: "#fff" }}>
                {t("2fa.otp_confirmation_wallet")}
              </p>
              <div className={styles.adaptive_wrapper}>
                <input
                  onInput={(e) => setOtpCode(e.target.value)}
                  value={otpCode}
                  className={styles.edit_input}
                  placeholder={t("2fa.otp_confirmation")}
                  type="text"
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div className={styles.button_wrapper}>
        <button
          className={styles.update_button}
          onClick={() => {
            updateUser();
          }}
        >
          {t("edit.9")}
        </button>
      </div>
    </div>
  );
}

import styles from "./authorization.module.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import axios from "axios"
import { useUser } from "../../providers/user";

export default function Authorization() {
  const [t, i18n] = useTranslation();
  const [isBlocked, setIsBlocked] = useState(false);
  const onSubmit = (data: any) => {};
  const [warning, setWarning] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const updateFields = () => {
    const element = document.getElementById('email');
    if (element) {
      const value = element.getAttribute('value');
      if (value !== null) {
        setEmail(value);
      }
    }
    const element2 = document.getElementById('password');
    if (element2) {
      const value = element2.getAttribute('value');
      if (value !== null) {
        setPassword(value);
      }
    }
  }
  useEffect(() => {
    updateFields();
  }, []);

  const { user, loading } = useUser();
  if (user) {
    window.location.href = "./dashboard";
  }
  const options = {
    url: urls.main + "/api/v1/auth/login",
    method: "POST",
    body: {
      email: email.toLowerCase().trim(),
      password: password,
    },
  };
  async function Login() {
    updateFields();
    setIsBlocked(true);
    await axios
      .post(options.url, options.body)
      .then((response: any) => {
        if (response.status == 200) {
          localStorage.setItem("token", `Bearer ${response.data.access_token}`);
          window.location.href = "./dashboard";
        }
      })
      .catch((error: any) => {
        setWarning(t("auth.5"));
        setWarning(error.response.data.detail);
      })
      .finally(() => {
        setIsBlocked(false);
      });
  }

  return (
    <div className={styles.adaptive_wrapper}>
      <div className={styles.authorization}>
        <h1 style={{ textAlign: "center" }}>{t("auth.1")}</h1>
        <div className={styles.authorization_inner}>
          <form
            onSubmit={onSubmit}
            className={styles.authorization_form}
          >
            <input
              id="email"
              name="email"
              onClick={() => setWarning("")}
              className={styles.auth_input}
              placeholder={t("auth.2")}
              onInput={(e: any) => setEmail(e.target.value)}
              value={email}
              type="text"
            />
            <input
              id="password"
              name="password"
              onClick={() => setWarning("")}
              className={styles.auth_input}
              placeholder={t("auth.3")}
              onInput={(e: any) => setPassword(e.target.value)}
              value={password}
              type="password"
            />
            <p style={{ color: "red", textAlign: "center", marginTop: "0" }}>
              {warning}
            </p>
            <div className={styles.button_wrapper}>
              <button
                disabled={isBlocked}
                style={{ opacity: isBlocked ? 0.5 : 1 }}
                className={styles.login_btn}
                onClick={() => Login()}
              >
                <FontAwesomeIcon icon={faSignIn} /> {t("auth.4")}
              </button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#fff", marginRight: "10px" }}>
                {t("auth.link")}
              </span>
              <Link className={styles.auth_link} to="/registration">
                {t("reg.12")}
              </Link>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#fff", marginRight: "10px" }}>
                {t("auth.forgot")}
              </span>
              <Link className={styles.auth_link} to="/reset-password">
                {t("auth.reset")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

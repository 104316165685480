import { Outlet } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import MobileWallet from "./components/mobilewallet/MobileWallet";

export default function Layout() {
  return (
    <div id="container_wrap">
      <Header />
      <MobileWallet />
      <Outlet />
      <Footer />
    </div>
  );
}

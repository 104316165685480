import { useState } from "react";
import styles from "./profileEdit.module.css";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import ModalWindow from "../../components/modal/ModalWindow";
import { useNavigate } from "react-router-dom";

const axios = require("axios").default;
export default function ProfileUpdatePassword() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  const [errorMessage, setErrorMessage] = useState("Error");
  const [isModal, setModal] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const token = localStorage.getItem("token");

  const updatePassword = () => {
    if (newPassword !== repeatPassword) {
      setErrorMessage(t("update_password.invalid_repeat"));
      setModal(true);
      return;
    }
    const options = {
      url: urls.main + "/api/v1/users/password",
      method: "PUT",
      headers: { Authorization: token },
      body: {
        old_password: oldPassword,
        new_password: newPassword,
      },
    };
    axios
      .put(options.url, options.body, options)
      .then((response) => {
        navigate("/profile");
        window.location.reload();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setModal(true);
      });
  };

  return (
    <div className={styles.edit_wrapper}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={errorMessage}
      />
      <h1 style={{ color: "#FDD536", textAlign: "center" }}>
        {t("update_password.title")}
      </h1>
      <div>
        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>{t("update_password.old_password")}</p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              className={styles.edit_input}
              placeholder={t("update_password.old_password")}
              type="password"
            />
          </div>
        </div>

        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>{t("update_password.new_password")}</p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              className={styles.edit_input}
              placeholder={t("update_password.new_password")}
              type="password"
            />
          </div>
        </div>

        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>
            {t("update_password.repeat_password")}
          </p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setRepeatPassword(e.target.value)}
              value={repeatPassword}
              className={styles.edit_input}
              placeholder={t("update_password.repeat_password")}
              type="password"
            />
          </div>
        </div>
      </div>

      <div className={styles.button_wrapper}>
        <button
          className={styles.update_button}
          onClick={() => {
            updatePassword();
          }}
        >
          {t("update_password.update")}
        </button>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import logo from "./img/dragonline-logo-light.png";
import pp from "./img/user.svg";
import UserProfile from "./UserProfile";
import { useTranslation } from "react-i18next";
import { useUser } from "../../providers/user";

export default function Header() {
  const { user, loading } = useUser();
  const [userEmail, setUserEmail] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [header, setHeader] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 5) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  useEffect(() => {
    if (loading) setIsAuthorized(false);
    else if (user) {
      setUserEmail(user.email);
      setIsAuthorized(true);
    }
  }, [user, loading]);

  window.addEventListener("scroll", changeBackground);
  const [t, i18n] = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <header className={header ? styles.header_active : styles.header}>
      <div className={styles.header_inner}>
        <Link to="/">
          <img className={styles.header_logo} src={logo} />
        </Link>
        <HeaderNav />
        <div className={styles.lang_wrapper}>
          <button
            onClick={() => changeLanguage("en")}
            className={styles.languageName}
          >
            EN
          </button>
          <button
            onClick={() => changeLanguage("ch")}
            className={styles.languageName}
          >
            中国人
          </button>
        </div>
        <UserProfile
          imageURL={pp}
          email={userEmail}
          isAuthorized={isAuthorized}
        />
      </div>
    </header>
  );
}

import React from "react";
import style from "./registration.module.css";
import { Link } from "react-router-dom";
export default function Agreement() {
  return (
    <div className={style.agreement}>
      <h2 style={{ color: "#c9ab5d" }}>TERMS OF SERVICES</h2>
      <p>T45 Development International FZ-LLC</p>
      <p>Effective: from 1 March 2024 until revoked</p>
      <div>
        <h3 style={{ color: "#50AF95" }}>1. Introduction</h3>
        <p>
          1.1 These Terms of Services (hereinafter referred to as the "ToS") govern the contractual relationship between T45 Development International FZ-LLC (hereinafter referred to as the "Service Provider"), having its registered office at FDBC0310 Compass Building, Al Shohada Road, AL Hamra Industrial Zone-FZ, Ras Al Khaimah, United Arab Emirates and the Client (hereinafter referred to as the "Client"). This ToS governs all details of the business relationship between the Client and the Service Provider, and acceptance of these ToS shall constitute a contractual relationship between the Parties.
        </p>
        <p>
          1.2 If, during the registration process, the Client ticks the box related to the ToS and thereby clicks on the option "I have read and accepted the ToS" or otherwise starts and continues to use the services subject to these ToS, he/she confirms that he/she has read and accepts these ToS and is bound by them and is therefore obliged to comply with their provisions; declares and warrants that, on behalf of itself or on behalf of an entity it represents, it is entitled and legally able to enter into a contract incorporating these ToS.
        </p>
        <p>
          1.3 If the Client does not accept all the terms and conditions of the ToS or has any objections, the Client shall not be entitled to use the services and platform subject to these ToS.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>2. General provisions</h3>
        <p>
          2.1 The Service Provider reserves the right, at any time and in its sole discretion, to change or modify the terms of this document, including but not limited to any policy, guidelines or details of the Services. It will notify the Client of any such changes by posting the amended ToS on the Website and changing the "effective" date on the header of the document, by sending an email to the Client at the email address provided, or by any other means deemed necessary by the Service Provider.
        </p>
        <p>
          2.2 If the Client disagrees with the Service Provider's current ToS, he/she must immediately stop using all services provided by the Service Provider.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>
          3. Registration, Customer identification
        </h3>
        <p>
          3.1 To use the Services, the Client must register on the website and create a Client Account. A Client Account may only be registered by a natural person who is at least 18 years of age and has the legal capacity to provide the information required by the anti-money laundering guidelines and legislation.
        </p>
        <p>
          3.2 All information provided when registering a Client Account must be true, accurate, complete, and up to date. It is strictly prohibited for the Client to register with false, fictitious, or abusive information or to attempt to create a Client Account on behalf of another person without authorisation. The representative acting on behalf of an entity with legal capacity shall ensure and warrant that he/she has the necessary power of representation, authority, and competence to do so.
        </p>
        <p>
          3.3 When registering, the Client must declare that he/she has read and accepted these ToS and the Service Provider's data processing documents.
        </p>
        <p>
          3.4 The Service Provider is not obliged to give reasons for accepting or rejecting the Client's request to create a Client Account.
        </p>
        <p>
          3.5 The Client shall be solely and exclusively responsible for the confidentiality of any information (e.g., passwords, codes, keys, etc.) related to the Client Account and for any activity that occurs through the Client Account, even if it results from the Client's failure to comply with its confidentiality obligations under this clause and keep the information secure. If the Client becomes aware of any unauthorised use of the Client Account, the Client shall immediately inform the Service Provider.
        </p>
        <p>
          3.6 Without the express and prior consent of the Service Provider, the Client may not transfer the Client Account to another person nor use another person's Client Account or the password belonging to it without the express and prior consent of the owner of the Client Account.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>
          4. The Service The Service Provider offers its Clients several services, which can be used separately or simultaneously
        </h3>
        <p>
          4.1 TradeForFive Grid robot trading 4.1.1 The Client may purchase a package via a USDT-based TRC20 or BEP20 chain. The minimum value of the package is 20 USDT. After 14 days of the purchase, the package generates profit for the client. The generated profit is credited to the Client's account.
        </p>
        <p>
          4.1.2 The commission is paid every second Monday into the Client’s wallet in USDT/BEP20 format. The withdrawal fee is 5%, and the minimum withdrawal amount is USDT 20. The withdrawal can be initiated on the day of withdrawal until 15:00 CET and will be settled after approval.
        </p>
        <p>
          4.1.3 If the Client's account with the Service Provider accumulates at least USD 20 in commissions, the Client has the option to use this balance to purchase a new package. The package purchase fee is 5%, which will be deducted from the Client's account balance.
        </p>
        <p>
          4.1.4 The Client's package remains active until the profit generated (Loyalty Bonus) reaches 300% of the value of the package. Once the profit reaches 300%, the package will no longer generate profit and the amount will remain with the Service Provider.
        </p>
        <p>
          4.1.5 The Client's referral commission rate is 10%, up to a tier depth. (In the future, the referral commission will be included in the 300% profit limit, which will be communicated to the Clients immediately upon entry into force.)
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>4.2 Trade45 token</h3>
        <p>
          4.2.1 The exclusive currency of the ecosystem is the Trade45 token. The pre-sale of Trade45 tokens prior to their issuance has started. Tokens purchased during the pre-sale will be credited at a 1:1 ratio at the time of issuance.
        </p>
        <p>
          4.2.2 Once issued, the tokens are staked for 2 years to control the circulation of the tokens. Each month, the Client participating in the staking will be credited with 2% of the value of the token purchased as a staking reward, with daily payments.
        </p>
        <p>
          4.2.3 You can participate in the token pre-sale at the Service Provider's web office. Once the Client Wallet has been set up and purchased, the Customer will receive an airdrop every Friday.
        </p>
        <p>
          4.2.4 The issue of the token completes the token pre-sale. As a Direct Commission, the system pays a total commission of 22% on five levels.
        </p>
        <p>
          4.2.5 The Pool Commission will be paid to the entitled persons on the last day of each month. The Pool Commission will be based on 10% of the total token pre-sales for that month. 50% of the Pool Commission will be paid in USDT and 50% in Trade45 pre-sale tokens.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>4.3 Price Prediction Game</h3>
        <p>
          4.3.1 The Price Prediction Game is a game that users can access using the Trade45 token. The Game operates on a DeFi (Decentralised Finance) platform and is fully transparent. The Game consists of players guessing whether the price of a given pair of coins will move up or down in the next 5 minutes following the start of the Game. If the player hits the price movement, they win.
        </p>
        <p>
          4.3.2 Only the Trade45 token may be used to participate in the game and pay bets. Accordingly, all prizes and other rewards paid will be paid in Trade45 tokens.
        </p>
        <p>
          4.3.3 Trade45 will be entitled to 5% of all bids made by players. The 5% commission is distributed as follows: 2% of the 5% is the Service Provider's fee. The remaining 3% is distributed among the users as follows: 0.25% will be paid to players who receive the referenced percentage for the bets of the players they have recommended in two levels of depth; 2.5% goes to the Profit Sharing Fund, from which Clients with a Price Prediction Game token will receive a share in proportion to the tokens they hold.
        </p>
        <p>
          4.3.4 The Prediction Game Token (PGT) is available for purchase by Clients through the web office. Once the Customer Wallet has been set up and purchased, the Client will receive an airdrop every Friday.
        </p>
        <p>
          4.3.5 PGT sales will cease from the start of the Price Prediction Game. The system will pay a total of 22% as referral commission (Direct Commission) up to 5 levels. The Pool Commission is paid to the eligible bidders on the last day of each month. The Pool Commission is based on 10% of the total PG token sales for the month. 50% of the Pool Commission is paid in USDT and the other 50% in PG tokens.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>4.4 Sniper robot - DragonLine</h3>
        <p>
          4.4.1 The Client may purchase a subscription(s) to use Sniper Robot. Purchased subscriptions can be redeemed for three months on the Service Provider's platform. After redemption, the Customer can use Sniper Robot for the time and with the services as per the subscription, to which he must connect his DeFi wallet. The Sniper Robot service is marketed through the DragonLine Marketing system.
        </p>
        <p>
          4.4.2 If the Customer fails to activate the subscription, the Sniper Robot cannot be used.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>
          5. Termination and withdrawal of capital
        </h3>
        <p>
          5.1 The Customer is entitled to terminate this contract at any time without giving any reason.
        </p>
        <p>
          5.2 The Service Provider shall be entitled to terminate the contract with immediate effect in the event that the Customer violates the provisions of this contract or gives the Service Provider a bad reputation, which tarnishes the Service Provider's image.
        </p>
        <p>
          5.3 The Customer may only use materials, promotions and information on social networking sites for marketing purposes that have been approved in advance by the Service Provider. Any breach of this shall constitute a serious breach of contract and shall be subject to the legal consequence of exclusion.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>6. Intellectual property rights</h3>
        <p>
          6.1 The Service Provider is the exclusive owner of the content shared by the Service Provider and the Service provided through it, as well as the intellectual property rights constituting the elements of the Website and all Services accessible through it.
        </p>
        <p>
          6.2 All subpages, text, graphics, other audiovisual elements, logos, user interfaces, artwork and computer code related to the above, including their design, structure, selection, coordination, expression and arrangement, are the intellectual property of the Service Provider and are protected by copyright, trademark and unfair market practice laws.
        </p>
        <p>
          6.3 The express prior written consent of the Service Provider is required before using any part of the Website and its intellectual property rights and audiovisual elements. Without express written consent, no part or content may be copied, reproduced, published, encoded, translated, transmitted, or distributed commercially.
        </p>
        <p>
          6.4 The Service Provider shall determine the scope, limits and conditions for granting such consent on an individual basis, which, like the possibility of rejecting a request without justification, shall be at its discretion.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>
          7. Data management and data protection
        </h3>
        <p>
          7.1 The Service Provider and its intermediary shall be entitled to process the Client's personal data in accordance with the EU General Data Protection Regulation (hereinafter referred to as "GDPR") and the applicable legislation, to the extent necessary for the performance of this contract.
        </p>
        <p>
          7.2 The legal basis for such processing of personal data for pre-contractual and contractual purposes is Article 6(1)(b) GDPR [processing necessary for the performance of the contract]. Without being exhaustive, this includes communication, invoicing, and contract amendment.
        </p>
        <p>
          7.3 A further legal basis for data processing is Article 6(1)(f) of the GDPR [protection of the legitimate interests of the Service Provider or third parties], whereby the Service Provider ensures, among other things, IT security and IT operation.
        </p>
        <p>
          7.4 In addition, the Service Provider also processes the Client's personal data for the purposes set out in Article 6(1)(c) GDPR, i.e. when the Service Provider is fulfilling its legal obligations (e.g. in the course of keeping financial legal records).
        </p>
        <p>
          7.5 If the Service Provider wishes to process the Client's personal data for a purpose not mentioned in the ToS, the Service Provider shall inform the Client in advance and request his/her explicit consent (by means of a separate legal declaration).
        </p>
        <p>
          7.6 The Client shall have the following rights about the processing of his personal data in accordance with the relevant legislation and the Service Provider's data processing documents: right of access, right of rectification, right to restriction of processing, right to erasure, right to object, right to complain.
        </p>
        <p>
          7.7 The Service Provider and its intermediary shall delete the Client's personal data as soon as they are no longer necessary for the purposes described. As a general principle, personal data shall be retained for as long as any claim against the Service Provider would be enforceable (limitation period) or for as long as the Service Provider is required to do so by law (e.g. accounting and tax provisions).
        </p>
        <p>
          7.8 The Service Provider draws the Client's attention to the fact that the data management and data protection information contained in these ToS describe only excerpts from the Service Provider's data management          documents. The Service Provider shall fulfil its obligation to provide the Client with complete prior information by publishing the data processing documents on the website and embedding a direct link to the
          declaration of awareness of the data processing documents during the registration or contracting process.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>8. Vis maior</h3>
        <p>
          8.1 For the purposes of this Agreement and the Website, the Client Account and the Services generally, force majeure shall mean any extraordinary circumstances beyond the Parties' control. Events that are unforeseeable and unavoidable by the Parties and which prevent or significantly limit the performance of this Agreement or the smooth operation of the Website, the Client Account, and the Services. Such as natural disasters, pandemics, fire, riots, war, civil war, acts of terrorism, general work stoppages, interruption of utility services and telecommunications or internet services, equipment and/or software failure.
        </p>
        <p>
          8.2 If either Party is prevented from performing its obligations by force majeure, it shall immediately notify the other Party. The Parties shall reasonably cooperate in dealing with the consequences of force majeure. For the duration of the force majeure, the Parties shall exclude the possibility of delays or other defaults in performance. Still, force majeure shall not relieve the Parties from obligations which would otherwise have been due and payable before the force majeure occurred.
        </p>
        <p>
          8.3 The Parties shall take all reasonable measures to eliminate or mitigate the consequences of the force majeure event. They shall resume the performance of their obligations without delay after the circumstances giving rise to the force majeure event have ceased to exist.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>9. Availability of the Services</h3>
        <p>
          9.1 The Service Provider will do its best to ensure that the Services will continue to operate but does not guarantee that the Client will always have access to the Website and the Client's account.
        </p>
        <p>
          9.2 In addition, the Service Provider does not guarantee uninterrupted operation of the Services. Delays, corruption, errors, data loss or data corruption may occur in the flow of information.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>10. Financial advice</h3>
        <p>
          10.1 Any information received in the form of a message from an employee, agent or associate of the Service Provider on the Service Provider's website or through the Client's account shall not constitute financial, investment or professional advice under any circumstances.
        </p>
        <p>
          10.2 You are solely responsible for any decision you make to hold, sell, or receive crypto assets, and such decisions must consider your risk tolerance and financial circumstances. For further information on such risks, please consult your legal or tax advisor to determine your position.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>11. Taxation</h3>
        <p>
          11.1 You are solely responsible for determining the amount and type of taxes applicable to the transactions in your Client Account and for declaring, paying and filing such taxes with the appropriate tax authorities.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>12. Prohibited activities</h3>
        <p>
          12.1 The Client may not use the Client Account to perform any of the activities or categories of activities listed below:
        </p>
        <p>
          12.1.1 Violation of any law, regulation or rule, including but not limited to money laundering, terrorist financing, malicious hacking; acts of violence, including but not limited to undue or disproportionate compromise of the Service Provider's infrastructure; or otherwise causing operational disruptions that may harm the operation of the Service or the Service Provider's rating.
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>13. Limitation of liability</h3>
        <p>
          13.1 The Service Provider reserves the right to suspend or interrupt the operation of the Website, the Client Account and the Services at any time for the purpose of periodic or extraordinary maintenance, bug fixes or other modifications, even without prior notice. In the case of scheduled maintenance and planned improvements, the Service Provider shall endeavour to inform its Clients in a timely manner of the start and expected duration of the suspension. The Service Provider shall have no liability in this regard, and the Client shall not be entitled to a refund of any fees due to the interruption.
        </p>
        <p>
          13.2 In this context, the Service Provider shall also have the right to modify and further develop the Website, the Client Account and the Services at any time without prior notice, thereby changing their content, the information contained therein and the conditions of access to them.
        </p>
        <p>
          13.3 The Service Provider shall not be liable for any malfunctioning of the Website or the bot resulting from the failure of the computer (hardware and/or software component) running the Service on the User's side. Furthermore, the Service Provider shall not be liable for any technical faults, malfunctions or data loss that may occur on the computer of the hosting server that enables the operation of the Service. In such cases, the Client may not claim damages from the Service Provider.
        </p>
        <p>
          13.4 The Service Provider expressly excludes any liability for consequential damages arising from the loss of the Service's uptime due to causes in the interest of third parties (e.g. hosting provider and Internet or power provider outages or maintenance).
        </p>
      </div>
      <div>
        <h3 style={{ color: "#50AF95" }}>14. Legal disputes</h3>
        <p>
          14.1 The Client and the Service Provider agree to notify the other party in writing of any claim or dispute arising out of the Service or using the Client Account within 30 days of the claim or dispute arising. The Client and the Service Provider agree to attempt to resolve the dispute amicably and reach a settlement before taking it to court or other forum.
        </p>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import styles from "./balance.module.css";
import { useTranslation } from "react-i18next";
import BalanceSlider from "./BalanceSlider";
import ModalWindow from "../../components/modal/ModalWindow";
import AdaptiveBalanceSlider from "./AdaptiveBalanceSlider";
import ReferralsTable from "./ReferralTable";
import PurchasesTable from "./PurchasesTable";
import TableBonuses from "./TableBonuses";
import axios from "axios";
import urls from "../../urls.json";
import Tree from "./Tree";

export default function Dashboard() {
  const [isShown, setIsShown] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [t, i18n] = useTranslation();
  const [message, setMessage] = useState("");
  const [direct, setDirect] = useState(0);
  const [singleline, setSingleline] = useState(0);

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [leftCount, setLeftCount] = useState(0);
  const [rightCount, setRightCount] = useState(0);
  const [current, setCurrent] = useState(null);
  const [activeReferrals, setActiveReferrals] = useState(0);
  const [turnover, setTurnover] = useState(0);

  useEffect(() => {
    const getLine = async () => {
      const token = localStorage.getItem("token");
      const url =
        urls.main +
        (urlParams.has("purchase_id")
          ? `/api/v1/purchases/${urlParams.get("purchase_id")}/line`
          : `/api/v1/purchases/line`);
      const headers = {
        Authorization: token,
      };
      try {
        const response = await axios.get(url, { headers });
        if (response.status === 200) {
          setLeft(response.data.left);
          setRight(response.data.right);
          setLeftCount(response.data.count_left);
          setRightCount(response.data.count_right);
          setCurrent(response.data.current);
        } else {
          console.error("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getLine();
  }, []);

  const getData = async () => {
    const token = localStorage.getItem("token");
    const url = urls.main + "/api/v1/summary/my";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setDirect(response.data.direct);
        setSingleline(response.data.singleline);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTurnover = async () => {
    const token = localStorage.getItem("token");
    const url = urls.main + "/api/v1/purchases/turnover";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setActiveReferrals(response.data.active_referrals);
        setTurnover(response.data.turnover);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTurnover();
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <ModalWindow setShown={setIsShown} isShown={isShown} text={message} />
      <h1 className={styles.title} style={{ color: "#c9ab5d" }}>
        {t("dashboard.title")}
      </h1>
      <div className={styles.components_container}>
        <div className={styles.text}>
          <span style={{ color: "#c9ab5d" }}>{t("dashboard.active_referrals")}</span>{" "}
          <span style={{ color: "#ffffff" }}>{activeReferrals}</span>
        </div>
        <div className={styles.text}>
          <span style={{ color: "#c9ab5d" }}>{t("dashboard.turnover")}</span>{" "}
          <span style={{ color: "#ffffff" }}>{turnover} USDT</span>
        </div>
      </div>

      <div className={styles.components_container}>
        <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
          {t("dashboard.subtitle")}
        </h2>
        {current != null && (
          <>
            <BalanceSlider
              left={left}
              right={right}
              leftCount={leftCount}
              rightCount={rightCount}
              current={current}
            />
            <AdaptiveBalanceSlider
              left={left}
              right={right}
              leftCount={leftCount}
              rightCount={rightCount}
              current={current}
            />
          </>
        )}
        <Tree />
        <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
          {t("dashboard.referrals")}
        </h2>
        <ReferralsTable />
        <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
          {t("dashboard.referrals_purchases")}
        </h2>
        <PurchasesTable />
        <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
          {t("dashboard.direct_bonuses")}
          <span style={{ color: "#fff", float: "right" }}>
            {direct.toFixed(0)} USDT
          </span>
        </h2>
        <TableBonuses type="direct" />
        <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
          {t("dashboard.singleline_bonuses")}
          <span style={{ color: "#fff", float: "right" }}>
            {singleline.toFixed(0)} USDT
          </span>
        </h2>
        <TableBonuses type="singleline" />
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import { useTranslation } from "react-i18next";

export default function FooterNav() {
  const [t, i18n] = useTranslation();

  return (
    <nav className={styles.footer_nav}>
      <Link to="/About" className={styles.footer_nav_link}>
        {t("header_nav.toAbout")}
      </Link>
    </nav>
  );
}

import styles from "./header.module.css";
import { useTranslation } from "react-i18next";

export default function HeaderNav() {
  const [t, i18n] = useTranslation();

  return (
    <nav className={styles.header_nav}>
      <div className={styles.wallet}>
        <w3m-button />
      </div>
    </nav>
  );
}

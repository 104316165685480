import { useEffect, useState } from "react";
import styles from "./qrcode.module.css";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import ModalWindow from "../../components/modal/ModalWindow";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../providers/user";
import axios from "axios";
import QRCodeSVG from "qrcode.react";

export default function Profile2FA() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { user, loading } = useUser();

  const [errorMessage, setErrorMessage] = useState("Error");
  const [isModal, setModal] = useState(false);

  const [connection, setConnection] = useState(null);
  const [otpEnable, setOtpEnable] = useState(false);
  const [userOTPEnable, setUserOTPEnable] = useState(false);
  const [otpLogin, setOtpLogin] = useState(false);
  const [otpWallet, setOtpWallet] = useState(false);
  const [otpConfirmation, setOtpConfirmation] = useState("");

  const token = localStorage.getItem("token");

  const getConnection = async () => {
    if (token) {
      const options = {
        url: urls.main + "/api/v1/otp/connection",
        method: "GET",
        headers: {
          Authorization: token,
        },
      };

      const response = await axios(options);
      if (response.status === 200) {
        setConnection(response.data.connection);
        setOtpEnable(response.data.otp_enable);
        setUserOTPEnable(response.data.otp_enable);
        setOtpLogin(response.data.otp_login);
        setOtpWallet(response.data.otp_wallet_change);
      } else {
        throw new Error("Failed to fetch user data");
      }
    }
  };

  const configureOTP = () => {
    const options = {
      url: urls.main + "/api/v1/otp/configure",
      method: "POST",
      headers: { Authorization: token },
      body: {
        otp_code: otpConfirmation,
        otp_enable: otpEnable,
        otp_login: otpLogin,
        otp_wallet_change: otpWallet,
      },
    };
    axios
      .post(options.url, options.body, options)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setModal(true);
      });
  };

  useEffect(() => {
    if (!loading) {
      getConnection();
    }
  }, [user, loading]);

  return (
    <div className={styles.edit_wrapper}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={errorMessage}
      />
      <h1 style={{ color: "#c9ab5d", textAlign: "center" }}>
        {t("2fa.header")}
      </h1>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          {!userOTPEnable && (
            <div className={styles.qrcode}>
              <QRCodeSVG className={styles.qrborder} value={connection} />
            </div>
          )}
          <div className={styles.edit_item}>
            <div className={styles.adaptive_wrapper}>
              <input
                type="checkbox"
                name="cbx1"
                onChange={(e) => setOtpEnable(e.target.checked)}
                checked={otpEnable}
                className={styles.customCheckbox}
              />
              <label htmlFor="cbx1" style={{ color: "#fff" }}>
                {t("2fa.otp_enable")}
              </label>
            </div>
          </div>
          {otpEnable && otpLogin == "qwe" && (
            <div className={styles.edit_item}>
              <div className={styles.adaptive_wrapper}>
                <input
                  type="checkbox"
                  name="cbx2"
                  onChange={(e) => setOtpLogin(e.target.checked)}
                  checked={otpLogin}
                  className={styles.customCheckbox}
                />
                <label htmlFor="cbx2" style={{ color: "#fff" }}>
                  {t("2fa.otp_login")}
                </label>
              </div>
            </div>
          )}
          {otpEnable && (
            <div className={styles.edit_item}>
              <div className={styles.adaptive_wrapper}>
                <input
                  type="checkbox"
                  name="cbx3"
                  onChange={(e) => setOtpWallet(e.target.checked)}
                  checked={otpWallet}
                  className={styles.customCheckbox}
                />
                <label htmlFor="cbx3" style={{ color: "#fff" }}>
                  {t("2fa.otp_wallet_change")}
                </label>
              </div>
            </div>
          )}
          <div className={styles.edit_item}>
            <p style={{ color: "#fff" }}>{t("2fa.otp_confirmation")}</p>
            <div className={styles.adaptive_wrapper}>
              <input
                onInput={(e) => setOtpConfirmation(e.target.value)}
                value={otpConfirmation}
                className={styles.edit_input}
                placeholder={t("2fa.otp_confirmation")}
                type="text"
              />
            </div>
          </div>
        </div>
      )}

      <div className={styles.button_wrapper}>
        <button
          className={styles.update_button}
          onClick={() => {
            configureOTP();
          }}
        >
          {t("2fa.save_button")}
        </button>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import styles from "./transactions.module.css";
import { useEffect } from "react";
import axios from "axios";
import urls from "../urls.json";
import { Link } from "react-router-dom";

export default function AdminSummary() {
  const [summary, setSummary] = useState(0);

  const getData = async () => {
    const token = localStorage.getItem("token");
    const url = urls.main + "/api/v1/summary/total";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setSummary(response.data);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.transactions_wrapper}>
      <Link to="/admin-panel">
        <button>Go to admin panel</button>
      </Link>
      <h1 style={{ color: "#FDD536" }}>
        Project Bonuses: {summary.project} USDT
      </h1>
      <h1 style={{ color: "#FDD536" }}>
        Direct Bonuses: {summary.direct} USDT
      </h1>
      <h1 style={{ color: "#FDD536" }}>Pool Bonuses: {summary.pool} USDT</h1>
      <h1 style={{ color: "#FDD536" }}>
        Singleline Bonuses: {summary.singleline} USDT
      </h1>
      <h1 style={{ color: "#FDD536" }}>
        Purchases amount: {summary.total_amount} USDT
      </h1>
      <h1 style={{ color: "#FDD536" }}>Profit: {summary.profit} USDT</h1>
    </div>
  );
}
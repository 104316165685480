import { useState, useContext } from "react";
import styles from "./packages.module.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { wagmiContractConfig } from "../../contracts/contract";
import {
  useAccount,
  useReadContract,
  useReadContracts,
  UseReadContractReturnType,
} from "wagmi";

import { Address, getContract } from "viem";
import { usePublicClient } from "wagmi";
import PackageList from "../packagelist/PackageList";
import { ethers } from "ethers";
import { useUser } from "../../providers/user";

export default function Packages() {
  const [t, i18n] = useTranslation();
  const [packages, setPackages] = useState([]);
  const { user, loading } = useUser();

  const { data, isError, isLoading } = useReadContract({
    ...wagmiContractConfig,
    functionName: "getAllPackages",
  });

  useEffect(() => {
    if (data) {
      const processedPackages = data.map((x) => ({
        id: x.id.toString(),
        imageUrl: x.imageUrl,
        name: x.name,
        price: parseInt(ethers.utils.formatUnits(x.price.toString(), "ether")),
        purchasable: x.purchasable,
      }));
      setPackages(processedPackages);
    }
    if (isError) {
      console.error(isError);
    }
  }, [data, isError]);

  return (
    <div className={styles.container}>
      <h1 data-aos="fade-right" className={styles.title}>
        {t("packages.title")}
      </h1>
      {!isLoading && (
        <>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <div className={styles.active_wrapper}>
              <PackageList
                packages={packages}
                userId={user.id}
                wallet={user.wallet}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

import { useTranslation } from "react-i18next";
import styles from "./purchase.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function ProgressBar({ current, limit }) {
  const [t, i18n] = useTranslation();
  const percentage = (current / limit) * 100;

  var percentageCss;
  if (percentage > 100) {
    percentageCss = 100;
  } else {
    percentageCss = percentage;
  }

  return (
    <div>
      <div
        style={{
          marginTop: "5px",
          width: "100%",
          backgroundColor: "#ddd",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            width: `${percentageCss}%`,
            backgroundColor: "#c9ab5d",
            height: "10px",
            borderRadius: "5px",
          }}
        ></div>
      </div>
      <p
        style={{ color: "#5cb85c", fontWeight: "bold" }}
      >{`${percentage.toFixed(2)}% ${t("purchases.completed")}`}</p>
    </div>
  );
}
const Purchase = ({ purchase }) => {
  const [t, i18n] = useTranslation();
  const copyToClipboard = (copyValue) => {
    navigator.clipboard.writeText(copyValue);
  };
  return (
    <div key={purchase.id} className={styles.container}>
      <div className={styles.first}>
        <img src={purchase.package.imageURL} alt={purchase.package.name} />
      </div>
      <div className={styles.second}>
        <div className={styles.container_header}>
          <div>
            <h3 className={styles.inner}>{purchase.package.name}</h3>
          </div>
          <div className={styles.clipboard}>
            <span>{purchase.voucher}</span>
            <button
              onClick={() => copyToClipboard(purchase.voucher)}
              className={styles.copyButton}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
        </div>
        <div className={styles.inner}>
          {purchase.gifted
            ? t("purchases.is_gift")
            : `${t("purchases.amount")} ${purchase.amount} USDT`}
        </div>
        <div className={styles.inner}>
          {t("purchases.bonuses")} {purchase.bonus_amount} USDT
        </div>
        <div className={styles.inner}>
          {t("purchases.max_bonuses")} {purchase.max_bonuses} USDT
        </div>
        <div className={styles.inner_progress}>
          <ProgressBar
            current={purchase.bonus_amount}
            limit={purchase.max_bonuses}
          />
        </div>
        <div className={styles.inner}>
          {t("purchases.date")}{" "}
          {new Date(purchase.created_at).toLocaleString("ru-RU")}{" "}
          {purchase.bonus_amount < purchase.max_bonuses && (
            <Link
              to={{
                pathname: "/dashboard",
                search: `?purchase_id=${purchase.id}`,
              }}
              style={{
                color: "green",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              {t("purchases.check_line")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Purchase;

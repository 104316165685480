import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import urls from "../urls.json";
import { useTranslation } from "react-i18next";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [t, i18n] = useTranslation();

  const setLanguage = () => {
    const lang = localStorage.getItem("i18nextLng");
    if (lang === "ch" || lang === "en") i18n.changeLanguage(lang);
  };
  const getUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const options = {
          url: urls.main + "/api/v1/users/me",
          method: "GET",
          headers: {
            Authorization: token,
          },
        };

        const response = await axios(options);
        if (response.status === 200) {
          setUser(response.data);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "./authorization";
        }
        throw error;
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      await getUser();
    };
    setLanguage();
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

import styles from "./button.module.css";

export default function Button(props: any) {
  return (
    <button
      style={{
        margin: props.margin,
        color: props.color,
        fontWeight: props.fw,
        backgroundColor: props.bg,
        width: props.width,
        border: props.border,
      }}
      className={styles.button}
    >
      {props.icon}
      {props.name}
    </button>
  );
}

import styles from "./wallet.module.css";
import { useTranslation } from "react-i18next";
import WalletHistoryTable from "./WalletHistoryTable";

export default function WalletHistory() {
  const [t, i18n] = useTranslation();

  return (
    <div className={styles.main_block}>
      <h1 style={{ color: "#c9ab5d" }} data-aos="fade-right">
        {t("wallet_history.title")}
      </h1>
      <div className={styles.container}>
        <div className={styles.container_data}>
          <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
            {t("wallet_history.subtitle")}
          </h2>
          <WalletHistoryTable />
        </div>
      </div>
    </div>
  );
}

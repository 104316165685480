import { useState } from "react";
import styles from "./management.module.css";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import urls from "../urls.json";
import ModalWindow from "../components/modal/ModalWindow";

export default function UserManagement() {
  const token = localStorage.getItem("token");
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState("");
  const [userId, setUserId] = useState("");
  const [parentId, setParentId] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("Error");
  const [isModal, setModal] = useState(false);

  const getData = async () => {
    const url = urls.main + `/api/v1/users/search?query=${query}`;
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setItems(response.data);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updatePassword = async () => {
    const url = urls.main + `/api/v1/users/${userId}/password`;
    const headers = {
      Authorization: token,
    };
    const body = {
      password: password,
    };
    try {
      const response = await axios.put(url, body, { headers });
      if (response.status === 200) {
        setErrorMessage("Password updated successfully");
        setModal(true);
        setUserId("");
      } else {
        setErrorMessage(response.data.detail);
        setModal(true);
      }
    } catch (error) {
      setErrorMessage(error.response.data.detail);
      setModal(true);
    }
  };

  const updateEmail = async () => {
    const url = urls.main + `/api/v1/users/${userId}/email`;
    const headers = {
      Authorization: token,
    };
    const body = {
      email: email,
    };
    try {
      const response = await axios.put(url, body, { headers });
      if (response.status === 200) {
        setErrorMessage("Email updated successfully");
        setModal(true);
        setUserId("");
      } else {
        setErrorMessage(response.data.detail);
        setModal(true);
      }
    } catch (error) {
      setErrorMessage(error.response.data.detail);
      setModal(true);
    }
  };

  const updateParent = async () => {
    const url = urls.main + `/api/v1/users/${userId}/parent`;
    const headers = {
      Authorization: token,
    };
    const body = {
      parent_id: parentId,
    };
    try {
      const response = await axios.put(url, body, { headers });
      if (response.status === 200) {
        setErrorMessage("ParentID updated successfully");
        setModal(true);
        setUserId("");
      } else {
        setErrorMessage(response.data.detail);
        setModal(true);
      }
    } catch (error) {
      setErrorMessage(error.response.data.detail);
      setModal(true);
    }
  };

  const handleChangeQuery = (event) => {
    setQuery(event.target.value);
  };

  return (
    <div className={styles.transactions_wrapper}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={errorMessage}
      />
      <h1 style={{ color: "#c9ab5d" }}>User management</h1>
      <Link to="/admin-panel">
        <button className={styles.goback_btn}>Go to admin panel</button>
      </Link>

      <div className={styles.formContainer}>
        <input
          className={styles.inputField}
          type="text"
          value={query}
          onChange={handleChangeQuery}
          placeholder="Query"
        />
        <button className={styles.submitButton} onClick={getData}>
          Find
        </button>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={styles.blackColor}>
            <TableRow>
              <TableCell data-aos="fade-right" align="center">
                ID
              </TableCell>
              <TableCell data-aos="fade-right" align="center">
                Email
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Username
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                First Name
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Last Name
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Parent ID
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Referrals
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Purchases
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Wallet
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                First Buy Date
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Referral Code
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Registration date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell data-aos="fade-right" align="center">
                  {row.id}
                </TableCell>
                <TableCell data-aos="fade-right" align="center">
                  {row.email}
                </TableCell>
                <TableCell data-aos="fade-right" align="center">
                  {row.username}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.first_name}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.last_name}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.parent_id}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  <Link to={`/admin-panel/referrals/${row.id}`}>Get</Link>
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  <Link to={`/admin-panel/purchases/${row.id}`}>Get</Link>
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.wallet}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {new Date(row.first_buy_date).toLocaleDateString("ru-RU")}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.referral_code}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {new Date(row.created_at).toLocaleString("ru-RU")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <h1 style={{ color: "#c9ab5d" }}>Update Parent for User</h1>
        <div className={styles.formContainer}>
          <input
            className={styles.inputField}
            type="text"
            value={userId}
            onChange={(event) => {
              setUserId(event.target.value);
            }}
            placeholder="User ID"
          />
          <input
            className={styles.inputField}
            type="text"
            value={parentId}
            onChange={(event) => {
              setParentId(event.target.value);
            }}
            placeholder="New Parent ID"
          />
          <button className={styles.submitButton} onClick={updateParent}>
            Update parent
          </button>
        </div>
      </div>
      <div>
        <h1 style={{ color: "#c9ab5d" }}>Update Password for User</h1>
        <div className={styles.formContainer}>
          <input
            className={styles.inputField}
            type="text"
            value={userId}
            onChange={(event) => {
              setUserId(event.target.value);
            }}
            placeholder="User ID"
          />
          <input
            className={styles.inputField}
            type="text"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            placeholder="New Password"
          />
          <button className={styles.submitButton} onClick={updatePassword}>
            Update password
          </button>
        </div>
      </div>
      <div>
        <h1 style={{ color: "#c9ab5d" }}>Update Email for User</h1>
        <div className={styles.formContainer}>
          <input
            className={styles.inputField}
            type="text"
            value={userId}
            onChange={(event) => {
              setUserId(event.target.value);
            }}
            placeholder="User ID"
          />
          <input
            className={styles.inputField}
            type="text"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            placeholder="New Email"
          />
          <button className={styles.submitButton} onClick={updateEmail}>
            Update Email
          </button>
        </div>
      </div>
    </div>
  );
}

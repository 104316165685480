import styles from "./mobilewallet.module.css";

export default function MobileWallet(props) {
  return (
    <div className={styles.mobile_wallet}>
      <div className={styles.mobile_wallet_wrapper}>
        <w3m-button />
      </div>
    </div>
  );
}

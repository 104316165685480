import { useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faGauge } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useUser } from "../../providers/user";

export default function DropMenu(props) {
  const [t, i18n] = useTranslation();
  const { user, loading } = useUser();
  const [isVisible, setInvisible] = useState(true);
  function logOut() {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setInvisible(true);
      }}
    >
      <div className={styles.user_header_account_container}>
        <Link className={styles.profile_link} to="/profile">
          <img className={styles.user_img} src={props.imageURL} />
        </Link>
        <div className={styles.user_header_burgerMenu_sm}>
          <div
            onClick={() => setInvisible(!isVisible)}
            className={styles.burger_menu}
          >
            <div className={styles.user_burgerMenu_item}></div>
            <div className={styles.user_burgerMenu_item}></div>
            <div className={styles.user_burgerMenu_item}></div>
          </div>
          <ul className={isVisible ? styles.dropDown_menu : styles.hidden_menu}>
            {!loading && user.is_admin ? (
              <Link className={styles.dropDown_item_wrap} to="/admin-panel">
                <li
                  onClick={() => setInvisible(!isVisible)}
                  className={styles.dropDown_item}
                >
                  <p className={styles.menu_link}>
                    <FontAwesomeIcon icon={faStar} />{t("header_menu.admin_panel")}
                  </p>
                </li>
              </Link>
            ) : null}
            <Link className={styles.dropDown_item_wrap} to="/profile">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}><FontAwesomeIcon icon={faUser} />{t("header_menu.profile")}</p>
              </li>
            </Link>
            <Link className={styles.dropDown_item_wrap} to="/dashboard">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}><FontAwesomeIcon icon={faGauge} />{t("header_menu.dashboard")}</p>
              </li>
            </Link>

            <Link className={styles.dropDown_item_wrap} to="/packages">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}><FontAwesomeIcon icon={faBoxesStacked} />{t("header_menu.packages")}</p>
              </li>
            </Link>
            <Link className={styles.dropDown_item_wrap} to="/purchases">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}><FontAwesomeIcon icon={faCartShopping} />{t("header_menu.purchases")}</p>
              </li>
            </Link>
            <Link className={styles.dropDown_item_wrap} to="/about">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}><FontAwesomeIcon icon={faQuestion} />{t("header_menu.toAbout")}</p>
              </li>
            </Link>
            <a className={styles.dropDown_item_wrap}>
              <li onClick={() => logOut()} className={styles.dropDown_item}>
                <p className={styles.menu_link}><FontAwesomeIcon icon={faRightFromBracket} />{t("footer.exit")}</p>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export const wagmiContractConfig = {
  address: "0x18807df28c6b2588602ec0506b45d7b720de5cfd",
  abi: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "uint256",
          name: "_purchaseId",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_recepient",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint8",
          name: "_bonusType",
          type: "uint8",
        },
      ],
      name: "BonusSent",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "_id",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "_name",
          type: "string",
        },
        {
          indexed: false,
          internalType: "string",
          name: "_imageUrl",
          type: "string",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "_purchasable",
          type: "bool",
        },
      ],
      name: "PackageAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "_purchaseId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "_userId",
          type: "string",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_sender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_packageId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "PackageGived",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "_purchaseId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "_userId",
          type: "string",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_packageId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "PackagePurchased",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "_packageId",
          type: "uint256",
        },
      ],
      name: "PackageUpdated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_recepient",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "UserWithdrawBonuses",
      type: "event",
    },
    {
      inputs: [
        { internalType: "string", name: "_userId", type: "string" },
        { internalType: "uint256", name: "_packageId", type: "uint256" },
      ],
      name: "buyPackage",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_price", type: "uint256" },
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_imageUrl", type: "string" },
        { internalType: "bool", name: "_purchasable", type: "bool" },
      ],
      name: "createPackage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "getAllPackages",
      outputs: [
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "price", type: "uint256" },
            { internalType: "string", name: "name", type: "string" },
            { internalType: "string", name: "imageUrl", type: "string" },
            { internalType: "bool", name: "purchasable", type: "bool" },
          ],
          internalType: "struct Dragonline.Package[]",
          name: "",
          type: "tuple[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_packageId", type: "uint256" },
      ],
      name: "getPackage",
      outputs: [
        { internalType: "uint256", name: "id", type: "uint256" },
        { internalType: "uint256", name: "price", type: "uint256" },
        { internalType: "string", name: "name", type: "string" },
        { internalType: "string", name: "imageUrl", type: "string" },
        { internalType: "bool", name: "purchasable", type: "bool" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getPackageCount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "_userId", type: "string" },
        { internalType: "uint256", name: "_packageId", type: "uint256" },
      ],
      name: "givePackage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_purchaseId", type: "uint256" },
        { internalType: "address[]", name: "_addresses", type: "address[]" },
        { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
        { internalType: "uint8[]", name: "_types", type: "uint8[]" },
      ],
      name: "sendBonuses",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_appWallet", type: "address" },
      ],
      name: "setAppWallet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_packageId", type: "uint256" },
        { internalType: "string", name: "_newImage", type: "string" },
      ],
      name: "updatePackageImage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_packageId", type: "uint256" },
        { internalType: "string", name: "_newName", type: "string" },
      ],
      name: "updatePackageName",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_packageId", type: "uint256" },
        { internalType: "uint256", name: "_newPrice", type: "uint256" },
      ],
      name: "updatePackagePrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_packageId", type: "uint256" },
        { internalType: "bool", name: "_purchasable", type: "bool" },
      ],
      name: "updatePackagePurchasable",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bool", name: "_value", type: "bool" }],
      name: "updatePackagePurchasable",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "withdraw",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_address", type: "address" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "withdrawBonuses",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "withdrawTokens",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
} as const;

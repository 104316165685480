import { useState } from "react";
import styles from "./purchases.module.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import Purchase from "./Purchase";
import axios from "axios";

export default function Purchases() {
  const [t, i18n] = useTranslation();

  const token = localStorage.getItem("token");
  if (token == null) {
    window.location.href = "./authorization";
  }

  const [purchases, setPurchases] = useState([]);

  const getPurchases = async () => {
    const url = urls.main + "/api/v1/purchases/my";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setPurchases(response.data);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPurchases();
  }, []);

  return (
    <div className={styles.container}>
      <h1 data-aos="fade-right" className={styles.title}>
        {t("purchases.title")}
      </h1>
      <div className={styles.active_wrapper}>
        <div data-aos="fade-right" className="package-container">
          {purchases.map((x) => (
            <Purchase purchase={x} />
          ))}
        </div>
      </div>
    </div>
  );
}

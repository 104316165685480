import Layout from "./Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Authorization from "./pages/authorization/Authorization";
import Dashboard from "./pages/dashboard/Dashboard";
import Packages from "./pages/packages/Packages";
import Profile from "./pages/profile/Profile";
import ProfileEdit from "./pages/profile/ProfileEdit";
import WalletHistory from "./pages/profile/WalletHistory";
import Registration from "./pages/registration/Registration";
import Agreement from "./pages/registration/Agreement";
import ProfileUpdatePassword from "./pages/profile/ProfileUpdatePassword";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import VerifyEmail from "./pages/verify/VerifyEmail";
import Purchases from "./pages/purchases/Purchases";
import About from "./pages/about/About";
import AdminPanel from "./admin panel/AdminPanel";
import AdminUsers from "./admin panel/AdminUsers";
import AdminReferrals from "./admin panel/AdminReferrals";
import AdminPurchases from "./admin panel/AdminPurchases";
import AdminBonuses from "./admin panel/AdminBonuses";
import AdminSummary from "./admin panel/AdminSummary";
import Profile2FA from "./pages/profile/Profile2FA";
import UserManagement from "./admin panel/UserManagement";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Authorization />} />
            <Route path="about" element={<About />} />
            <Route path="authorization" element={<Authorization />} />
            <Route path="registration" element={<Registration />} />
            <Route path="agreement" element={<Agreement />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/edit" element={<ProfileEdit />} />
            <Route path="configure-2fa" element={<Profile2FA />} />
            <Route path="update-password" element={<ProfileUpdatePassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="wallet-history" element={<WalletHistory />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="packages" element={<Packages />} />
            <Route path="purchases" element={<Purchases />} />
            <Route path="/admin-panel" element={<AdminPanel />} />
            <Route path="/admin-panel/users" element={<AdminUsers />} />
            <Route
              path="/admin-panel/referrals/:user_id"
              element={<AdminReferrals />}
            />
            <Route path="/admin-panel/purchases" element={<AdminPurchases />} />
            <Route path="/admin-panel/bonuses" element={<AdminBonuses />} />
            <Route path="/admin-panel/summary" element={<AdminSummary />} />
            <Route
              path="/admin-panel/purchases/:user_id"
              element={<AdminPurchases />}
            />
            <Route path="/admin-panel/user-management" element={<UserManagement />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

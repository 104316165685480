import styles from "./profile.module.css";
import Pp from "./img/logo.png";
import { useTranslation } from "react-i18next";
import { useUser } from "../../providers/user";

export default function UserData() {
  const [t, i18n] = useTranslation();

  const { user, loading } = useUser();

  return (
    <div className={styles.user_data} data-aos="fade-right">
      <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
        {t("profile.2")}
      </h2>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div className={styles.user_data_wrapper} data-aos="fade-right">
          <div data-aos="fade-right" className={styles.pp_wrapper}>
            <img className={styles.pp_img} src={Pp} />
          </div>
          <div className={styles.user_data_info}>
            <div>
              <p data-aos="fade-left" className={styles.user_data_info_title}>
                E-mail
              </p>
              <p
                data-aos="fade-left"
                className={styles.user_data_info_text}
                style={{ word: "break-all" }}
              >
                {user.email}
              </p>
            </div>
            <div>
              <p data-aos="fade-left" className={styles.user_data_info_title}>
                {t("profile.15")}
              </p>
              <p
                data-aos="fade-left"
                className={styles.user_data_info_text}
                style={{ word: "break-all" }}
              >
                {user.wallet}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

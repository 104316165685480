import React from "react";
import styles from "./adminPanel.module.css";
import { Link } from "react-router-dom";
import Button from "../components/button/Button";

export default function AdminPanel() {
  return (
    <div className={styles.admin_panel_wrapper}>
      <h1 style={{ color: "#c9ab5d" }}>Admin dashboard</h1>
      <div className={styles.admin_control_panel}>
        <Link className={styles.item} to="/admin-panel/users">
          <Button
            fw={"700"}
            bg={"#FBD539"}
            border={"none"}
            width={"330px"}
            name={"Users"}
          />
        </Link>
        <Link className={styles.item} to="/admin-panel/purchases">
          <Button
            fw={"700"}
            bg={"#FBD539"}
            border={"none"}
            width={"330px"}
            name={"Purchases"}
          />
        </Link>
         <Link className={styles.item} to="/admin-panel/bonuses">
          <Button
            fw={"700"}
            bg={"#FBD539"}
            border={"none"}
            width={"330px"}
            name={"Bonuses"}
          />
        </Link>
        <Link className={styles.item} to="/admin-panel/summary">
          <Button
            fw={"700"}
            bg={"#FBD539"}
            border={"none"}
            width={"330px"}
            name={"Summary information"}
          />
        </Link>
        <Link className={styles.item} to="/admin-panel/user-management">
          <Button
            fw={"700"}
            bg={"#FBD539"}
            border={"none"}
            width={"330px"}
            name={"User management"}
          />
        </Link>
      </div>
    </div>
  );
}

import PersonalData from "./PersonalData";
import UserData from "./UserData";
import UserLink from "./UserLink";
import styles from "./profile.module.css";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const [t, i18n] = useTranslation();
  return (
    <div className={styles.profile}>
      <h1 style={{ color: "#c9ab5d" }} data-aos="fade-right">
        {t("profile.1")}
      </h1>
      <div className={styles.components_container}>
        <UserData />
        <UserLink />
        <PersonalData />
      </div>
    </div>
  );
}

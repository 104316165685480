import * as React from "react";
import styles from "./tree.module.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import axios from "axios";
import urls from "../../urls.json";
import { useEffect, useState } from "react";

const CustomTreeItem = styled(TreeItem)({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
});

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function round(value) {
  return Math.round(parseFloat(value) * 100) / 100;
}

function renderTree(nodes, i = 0) {
  return nodes.map((node) => (
    <TreeItem
      key={node.user}
      itemId={`referral-${node.user}-${i++}`}
      label={`${node.user} | Bonuses received: ${round(node.total_bonus_amount)} USDT | Bonuses limit: ${round(node.total_max_bonus_amount)} USDT`}
      className={node.active ? styles.active : styles.inactive}
    >
      {Array.isArray(node.children) && node.children.length > 0
        ? renderTree(node.children, i)
        : null}
    </TreeItem>
  ));
}

export default function Tree() {
  const [referrals, setReferrals] = useState([]);
  const [referrals2, setReferrals2] = useState([]);

  const getData = async () => {
    const token = localStorage.getItem("token");
    const url = urls.main + "/api/v1/referrals/tree";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setReferrals(response.data);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getData2 = async () => {
    const token = localStorage.getItem("token");
    const url = urls.main + "/api/v1/referrals/treeview";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setReferrals2(response.data.children);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
    getData2();
  }, []);

  return (
    <>
      {referrals.length > 0 && (
        <div className={styles.container}>
          <SimpleTreeView
            className={styles.tree}
            aria-label="customized"
            defaultExpandedItems={["1"]}
            slots={{
              expandIcon: AddBoxIcon,
              collapseIcon: IndeterminateCheckBoxIcon,
              endIcon: CloseSquare,
            }}
            sx={{
              overflowX: "hidden",
              flexGrow: 1,
              maxWidth: "100%",
              padding: "15px",
            }}
          >
            <CustomTreeItem itemId="1" label="Your referrals">
              {referrals.map((referral, index) => (
                <CustomTreeItem
                  key={index}
                  itemId={`referral-${index}`}
                  label={`User: ${referral.username}`}
                  className={referral.active ? styles.active : styles.inactive}
                >
                  {referral.purchases.length > 0 &&
                    referral.purchases.map((purchase, purchaseIndex) =>
                      purchase.bonus_amount < purchase.max_bonus_amount ? (
                        <CustomTreeItem
                          key={purchaseIndex}
                          itemId={`purchase-${index}-${purchaseIndex}`}
                          label={`Package: ${purchase.package}`}
                        >
                          <CustomTreeItem
                            key={purchaseIndex}
                            itemId={`received-${index}-${purchaseIndex}`}
                            label={`Received: ${purchase.bonus_amount} USDT`}
                          />
                          <CustomTreeItem
                            key={purchaseIndex}
                            itemId={`limit-${index}-${purchaseIndex}`}
                            label={`Limit: ${purchase.max_bonus_amount} USDT`}
                          />
                        </CustomTreeItem>
                      ) : (
                        <CustomTreeItem
                          key={purchaseIndex}
                          itemId={`purchase-${index}-${purchaseIndex}`}
                          label={`Package: ${purchase.package}. Received: ${purchase.bonus_amount} USDT [completed]`}
                        />
                      )
                    )}
                </CustomTreeItem>
              ))}
            </CustomTreeItem>
          </SimpleTreeView>
        </div>
      )}
      {referrals2.length > 0 && (
        <div className={styles.container}>
          <SimpleTreeView
            className={styles.tree}
            aria-label="customized"
            defaultExpandedItems={["1"]}
            slots={{
              expandIcon: AddBoxIcon,
              collapseIcon: IndeterminateCheckBoxIcon,
              endIcon: CloseSquare,
            }}
            sx={{
              overflowX: "hidden",
              flexGrow: 1,
              maxWidth: "100%",
              padding: "15px",
            }}
          >
            <CustomTreeItem itemId="1" label="Full referral structure">
              {renderTree(referrals2)}
            </CustomTreeItem>
          </SimpleTreeView>
        </div>
      )}
    </>
  );
}

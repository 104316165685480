import React, { useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { Trans, useTranslation } from "react-i18next";
export default function DropMenu() {
  const [t, i18n] = useTranslation();
  const [isVisible, setInvisible] = useState(true);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setInvisible(true);
      }}
    >
      <div className={styles.adaptive_header_menu_container}>
        <div className={styles.user_header_burgerMenu_sm}>
          <div
            onClick={() => setInvisible(!isVisible)}
            className={styles.burger_menu}
          >
            <div className={styles.user_burgerMenu_item}></div>
            <div className={styles.user_burgerMenu_item}></div>
            <div className={styles.user_burgerMenu_item}></div>
          </div>
          <ul className={isVisible ? styles.dropDown_menu : styles.hidden_menu}>
            <Link className={styles.dropDown_item_wrap} to="/authorization">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}>{t("header_menu.logIn")}</p>
              </li>
            </Link>

            <Link className={styles.dropDown_item_wrap} to="/registration">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}>{t("header_menu.signIn")}</p>
              </li>
            </Link>
            <Link className={styles.dropDown_item_wrap} to="/about">
              <li
                onClick={() => setInvisible(!isVisible)}
                className={styles.dropDown_item}
              >
                <p className={styles.menu_link}>{t("header_nav.toAbout")}</p>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

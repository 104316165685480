import DropMenu from "./DropMenu";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import AdaptiveDropMenu from "./AdaptiveDropMenu";
import pp from "./img/user.svg";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

export default function UserProfile(props: any) {
  const [t, i18n] = useTranslation();
  if (props.isAuthorized) {
    return (
      <div className={styles.authorized_wrapper}>
        <Link to="/profile">
          <img className={styles.profile_link_img} src={pp} alt="" />
        </Link>
        <DropMenu imageURL={props.imageURL} email={props.email} />
      </div>
    );
  } else {
    return (
      <div>
        <div className={styles.button_container}>
          <Link to="authorization">
            <Button
              width={"130px"}
              margin={"0 10px 0  0"}
              color={"#c9ab5d"}
              fw={"700"}
              bg={"#132f2e"}
              border={"1px solid #c9ab5d"}
              name={t("header_buttons.logIn")}
              icon={<FontAwesomeIcon icon={faSignIn} />}
            />
          </Link>
          <Link to="registration">
            <Button
              width={"130px"}
              fw={"700"}
              color={"#132f2e"}
              bg={"#c9ab5d"}
              border={"none"}
              name={t("header_buttons.signIn")}
              icon={<FontAwesomeIcon icon={faUserPlus} />}
            />
          </Link>
        </div>
        <AdaptiveDropMenu />
      </div>
    );
  }
}

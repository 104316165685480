import styles from "./modal.module.css";
import OutsideClickHandler from "react-outside-click-handler";
export default function ModalWindow(props) {
  return (
    <div className={props.isShown ? styles.modal : styles.hidden_modal}>
      <OutsideClickHandler
        onOutsideClick={() => {
          props.setShown(false);
        }}
      >
        <div className={styles.modal_dialog}>
          <div className={styles.exit}>
            <p className={styles.modal_text}>
              <button
                className={styles.close_btn}
                onClick={() => props.setShown(false)}
              >
                &times;
              </button>
            </p>
          </div>
          <p style={{ textAlign: "center", marginTop: "15px" }}>{props.text}</p>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

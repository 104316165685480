import { useState } from "react";
import styles from "./profile.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../providers/user";

export default function UserLink() {
  const [t, i18n] = useTranslation();

  const { user, loading } = useUser();

  const [notificationVisible, setNotificationVisible] = useState(false);

  function copyReferralCode() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    const link = `${protocol}//${host}/registration?referral_code=${user.referral_code}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setNotificationVisible(true);
        setTimeout(() => {
          setNotificationVisible(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Failed to copy referral code: ", error);
      });
  }

  return (
    <div
      className={`${styles.user_data} ${styles.ref_wrapper}`}
      data-aos="fade-right"
    >
      <h2 data-aos="fade-right" style={{ color: "#c9ab5d" }}>
        {t("profile.16")}
      </h2>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div className={styles.user_data_wrapper} data-aos="fade-right">
          <div
            data-aos="fade-right"
            className={styles.personal_data_option_item}
            style={{ width: "100%", border: "none" }}
          >
            <p
              data-aos="fade-right"
              data-aos-delay="300"
              className={styles.persona_data_option_title}
            >
              {t("profile.16")}
            </p>
            <div className={styles.option_text_container}>
              <p
                data-aos="fade-left"
                data-aos-delay="300"
                className={`${styles.personal_data_option_text} ${styles.refferal_link}`}
              >
                {user.referral_code}
              </p>
              <button className={styles.copyButton} onClick={copyReferralCode}>
                <FontAwesomeIcon icon={faCopy} /> {t("profile.copy")}
              </button>
              {notificationVisible ? (
                <p
                  data-aos="fade-left"
                  data-aos-delay="300"
                  className={`${styles.notification} ${styles.refferal_link}`}
                >
                  <FontAwesomeIcon icon={faCheck} /> {t("profile.copied")}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

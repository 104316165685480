import { useRef } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import "./slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./balanceSlider.module.css";
import { useTranslation } from "react-i18next";

export default function AdaptiveBalanceSlider({
  left,
  right,
  leftCount,
  rightCount,
  current,
}) {
  const [t, i18n] = useTranslation();
  const sliderRef = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    sliderRef.current.slickGoTo(left.length);
  }, []);

  function renderSliderAdapt() {
    return (
      <Slider ref={sliderRef} className={style.slider_adapt} {...settings}>
        {left.map((x) => (
          <div className={style.slide} key={x.id}>
            <img
              className={style.userIMG}
              src={x.package_image}
              alt={x.package_name}
            />
            <p className={style.text}>{x.user_name}</p>
            <p className={style.side}>{t("dashboard.left")}</p>
            <div className={style.slide_info}>
              <p className={style.line_place}>{x.package_name}</p>
              <p className={style.package}>{x.package_price} USDT</p>
            </div>
          </div>
        ))}
        {
          <div className={style.slide} key={current.id}>
            <img
              className={style.userIMG}
              src={current.package_image}
              alt={current.package_name}
            />
            <p className={style.text}>{current.user_name}</p>
            <p className={style.side}>{t("dashboard.current")}</p>
            <div className={style.slide_info}>
              <p className={style.line_place}>{current.package_name}</p>
              <p className={style.package}>{current.package_price} USDT</p>
            </div>
          </div>
        }
        {right.map((x) => (
          <div className={style.slide} key={x.id}>
            <img
              className={style.userIMG}
              src={x.package_image}
              alt={x.package_name}
            />
            <p className={style.text}>{x.user_name}</p>
            <p className={style.side}>{t("dashboard.right")}</p>
            <div className={style.slide_info}>
              <p className={style.line_place}>{x.package_name}</p>
              <p className={style.package}>{x.package_price} USDT</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }

  return (
    <div className={style.slider_wrapper_adapt}>
      <div className={style.slider_count_wrapper}>
        <h3>
          {t("dashboard.left_count")}: {leftCount}
        </h3>
        <h3>
          {t("dashboard.right_count")}: {rightCount}
        </h3>
      </div>
      <div className={style.slider_inner}>{renderSliderAdapt()}</div>
    </div>
  );
}

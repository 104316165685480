import { useRef } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import "./slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./balanceSlider.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function BalanceSlider({
  left,
  right,
  leftCount,
  rightCount,
  current,
}) {
  const [t, i18n] = useTranslation();
  const sliderRef = useRef(null);
  var settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  useEffect(() => {
    sliderRef.current.slickGoTo(left.length);
  }, []);

  function renderSlider() {
    return (
      <Slider ref={sliderRef} className={style.slider} {...settings}>
        {left.map((x) => (
          <div className={style.slide} key={x.id}>
            <img
              className={style.userIMG}
              src={x.package_image}
              alt={x.package_name}
            />
            <p className={style.text}>{x.user_name}</p>
            <p className={style.side}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("dashboard.left")}
            </p>
            <div className={style.slide_info}>
              <p className={style.line_place}>{x.package_name}</p>
              <p className={style.price}>$ {x.package_price}</p>
            </div>
          </div>
        ))}
        {
          <div className={style.slide} key={current.id}>
            <img
              className={style.userIMG}
              src={current.package_image}
              alt={current.package_name}
            />
            <p className={style.text}>{current.user_name}</p>
            <p className={style.side}>
              <FontAwesomeIcon icon={faArrowRight} /> {t("dashboard.current")}{" "}
              <FontAwesomeIcon icon={faArrowLeft} />
            </p>
            <div className={style.slide_info}>
              <p className={style.line_place}>{current.package_name} </p>
              <p className={style.price}>$ {current.package_price}</p>
            </div>
          </div>
        }
        {right.map((x) => (
          <div className={style.slide} key={x.id}>
            <img
              className={style.userIMG}
              src={x.package_image}
              alt={x.package_name}
            />
            <p className={style.text}>{x.user_name}</p>
            <p className={style.side}>
              {t("dashboard.right")} <FontAwesomeIcon icon={faArrowRight} />
            </p>
            <div className={style.slide_info}>
              <p className={style.line_place}>{x.package_name}</p>
              <p className={style.price}>$ {x.package_price}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }

  return (
    <div className={style.slider_wrapper}>
      <div className={style.slider_count_wrapper}>
        <h2>
          {t("dashboard.left_count")}: {leftCount}
        </h2>
        <h2>
          {t("dashboard.right_count")}: {rightCount}
        </h2>
      </div>
      <div className={style.slider_inner}>{renderSlider()}</div>
    </div>
  );
}

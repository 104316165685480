import { useState } from "react";
import styles from "./resetPassword.module.css";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import ModalWindow from "../../components/modal/ModalWindow";
import axios from "axios";

export default function ResetPassword() {
  const [t, i18n] = useTranslation();

  const [errorMessage, setErrorMessage] = useState("Error");
  const [isModal, setModal] = useState(false);

  const [email, setEmail] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const resetPassword = () => {
    const options = {
      url: urls.main + "/api/v1/users/reset-password",
      method: "POST",
      body: {
        email: email,
      },
    };
    axios
      .post(options.url, options.body, options)
      .then((response) => {
        setErrorMessage(t("reset_password.code_sent"));
        setModal(true);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setModal(true);
      });
  };

  const setNewPassword = () => {
    if (password !== repeatPassword) {
      setErrorMessage(t("update_password.invalid_repeat"));
      setModal(true);
      return;
    }
    const options = {
      url: urls.main + "/api/v1/users/update-password",
      method: "POST",
      body: {
        email: email,
        confirmation: confirmation,
        password: password,
      },
    };
    axios
      .post(options.url, options.body, options)
      .then((response) => {
        setErrorMessage(t("reset_password.password_changed"));
        setModal(true);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setModal(true);
      });
  };

  return (
    <div className={styles.edit_wrapper}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={errorMessage}
      />
      <h1 style={{ color: "#FDD536", textAlign: "center" }}>
        {t("reset_password.title")}
      </h1>
      <div>
        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>{t("reset_password.email")}</p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setEmail(e.target.value)}
              value={email}
              className={styles.edit_input}
              placeholder={t("reset_password.email")}
              type="text"
            />
          </div>
        </div>
      </div>

      <div className={styles.button_wrapper}>
        <button
          className={styles.update_button}
          onClick={() => {
            resetPassword();
          }}
        >
          {t("reset_password.reset")}
        </button>
      </div>
      <h1 style={{ color: "#FDD536" }}>{t("reset_password.title2")}</h1>
      <div>
        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>{t("reset_password.email")}</p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setEmail(e.target.value)}
              value={email}
              className={styles.edit_input}
              placeholder={t("reset_password.email")}
              type="text"
            />
          </div>
        </div>
        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>{t("reset_password.confirmation")}</p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setConfirmation(e.target.value)}
              value={confirmation}
              className={styles.edit_input}
              placeholder={t("reset_password.confirmation")}
              type="text"
            />
          </div>
        </div>
        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>{t("update_password.new_password")}</p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setPassword(e.target.value)}
              value={password}
              className={styles.edit_input}
              placeholder={t("update_password.new_password")}
              type="password"
            />
          </div>
        </div>
        <div className={styles.edit_item}>
          <p style={{ color: "#fff" }}>
            {t("update_password.repeat_password")}
          </p>
          <div className={styles.adaptive_wrapper}>
            <input
              onInput={(e) => setRepeatPassword(e.target.value)}
              value={repeatPassword}
              className={styles.edit_input}
              placeholder={t("update_password.repeat_password")}
              type="password"
            />
          </div>
        </div>
      </div>

      <div className={styles.button_wrapper}>
        <button
          className={styles.update_button}
          onClick={() => {
            setNewPassword();
          }}
        >
          {t("reset_password.set")}
        </button>
      </div>
    </div>
  );
}

import FooterNav from "./FooterNav";
import styles from "./footer.module.css";
import Logo from "./img/dragonline-logo-light.png";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footer_inner}>
        <div className={styles.footer_info}>
          <div className={styles.footer_adaptive}>
            <img className={styles.footer_logo} src={Logo} />
          </div>
        </div>
        <FooterNav />
      </div>
    </div>
  );
}

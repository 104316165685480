import React from 'react'
import styles from './About.module.css'
import AboutInfo from './AboutInfo'
export default function About() {
  return (
    <div className={styles.about}>
        <AboutInfo/>
    </div>
  )
}

import { useEffect, useState } from "react";
import styles from "./verifyEmail.module.css";
import { useTranslation } from "react-i18next";
import ModalWindow from "../../components/modal/ModalWindow";
import urls from "../../urls.json";
import axios from "axios";

export default function VerifyEmail() {
  const [t, i18n] = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const [verified, setVerified] = useState(false);

  const verifyEmail = () => {
    const options = {
      url: urls.main + "/api/v1/auth/verify-email",
      method: "POST",
      body: {
        code: code,
      },
    };
    axios
      .post(options.url, options.body, options)
      .then((response) => {
        setVerified(true);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setModal(true);
      });
  };

  useEffect(() => {
    if (code) {
      verifyEmail();
    } else {
      setErrorMessage(t("verify_email.error"));
      setModal(true);
    }
  }, [code]);

  const [errorMessage, setErrorMessage] = useState("Error");
  const [isModal, setModal] = useState(false);

  return (
    <div className={styles.block}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={errorMessage}
      />
      {verified && (
        <div>
          <h1 className={styles.title}>{t("verify_email.title")}</h1>
          <p className={styles.message}>{t("verify_email.message")}</p>
        </div>
      )}
    </div>
  );
}

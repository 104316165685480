import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import urls from "../../urls.json";
import styles from "./wallet.module.css";
import axios from "axios";

export default function WalletHistoryTable() {
  const token = localStorage.getItem("token");
  if (token == null) {
    window.location.href = "./authorization";
  }

  const [history, setHistory] = useState([]);
  const getPurchases = async () => {
    const url = urls.main + "/api/v1/users/me/wallet/history";
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setHistory(response.data);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPurchases();
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div data-aos="fade-right">
      <TableContainer className={styles.tablewrapper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell data-aos="fade-right" align="center">
                Wallet Address
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell data-aos="fade-right" align="center">
                  {row.wallet}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {`${new Date(row.created_at).toLocaleDateString(
                    "ru-RU"
                  )} ${new Date(row.created_at).toLocaleTimeString("ru-RU")}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

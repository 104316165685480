import React from "react";
import styles from "./About.module.css";
import { useTranslation } from "react-i18next";

export default function AboutInfo() {
  const [t, i18n] = useTranslation();

  return (
    <div className={styles.about_info}>
      <div className={styles.about_info_inner}>
        <div>
          <h1 data-aos="fade-right" className={styles.title}>
            {t("about.1")}
          </h1>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.2")}
          </p>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.21")}
          </p>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.22")}
          </p>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.23")}
          </p>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.24")}
          </p>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.25")}
          </p>
          <p data-aos="fade-right" className={styles.sub_title}>
            {t("about.26")}
          </p>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import urls from "../../urls.json";
import styles from "./balance.module.css";
import { TablePagination } from "@mui/material";
import axios from "axios";

export default function ReferralsTable() {
  const token = localStorage.getItem("token");
  const [referrals, setReferral] = useState([]);
  const [countReferrals, setCountReferrals] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPage, setRowsPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRows = (event) => {
    setRowsPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getReferrals = async () => {
    const url =
      urls.main +
      `/api/v1/referrals?offset=${page * rowsPage}&limit=${rowsPage}`;
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setReferral(response.data.items);
        setCountReferrals(response.data.count);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getReferrals();
  }, []);

  useEffect(() => {
    getReferrals();
  }, [page]);

  return (
    <div data-aos="fade-right">
      <TableContainer className={styles.tablewrapper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell data-aos="fade-right" align="center">
                Username
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Wallet
              </TableCell>
              <TableCell data-aos="fade-left" align="center">
                Date registration
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell data-aos="fade-right" align="center">
                  {row.username}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {row.wallet
                    ? `${row.wallet.slice(0, 6)}...${row.wallet.slice(-4)}`
                    : ""}
                </TableCell>
                <TableCell data-aos="fade-left" align="center">
                  {new Date(row.created_at).toLocaleString("ru-RU")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          style={{ color: "#fff", backgroundColor: "#132f2e" }}
          component="div"
          count={countReferrals}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPage}
          onRowsPerPageChange={handleChangeRows}
        />
      </TableContainer>
    </div>
  );
}

import { useState } from "react";
import styles from "./registration.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../urls.json";
import ModalWindow from "../../components/modal/ModalWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../providers/user";
import axios from "axios";

export default function Registration() {
  const { user, loading } = useUser();
  if (user) {
    window.location.href = "./profile";
  }
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get("referral_code");
  const [t, i18n] = useTranslation();
  const [isBlocked, setIsBlocked] = useState(true);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isModal, setModal] = useState(false);
  const [text, setText] = useState("Error");
  const [referalLink, setReferalLink] = useState(referralCode);
  const [checkbox, setCheckbox] = useState("");
  const [isValidWallet, setIsValidWallet] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleInputWallet = (e) => {
    const inputValue = e.target.value;
    setWalletAddress(inputValue);
    if (inputValue.startsWith("0x") && inputValue.length === 42) {
      setIsValidWallet(true);
    } else {
      setIsValidWallet(false);
    }
  };

  const handleInputEmail = (e) => {
    const inputValue = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(inputValue);
    if (emailPattern.test(inputValue)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const handleInputAgreement = (e) => {
    const inputValue = e.target.checked;
    setIsBlocked(!inputValue);
    setCheckbox(inputValue);
  };

  const options = {
    url: urls.main + "/api/v1/auth/register",
    method: "POST",
    body: {
      username: username,
      email: email,
      first_name: name,
      last_name: surname,
      password: password,
      referral_code: referalLink,
      wallet: walletAddress,
    },
  };
  async function Register() {
    setIsBlocked(true);
    await axios
      .post(options.url, options.body)
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem("token", "Bearer " + response.data.access_token);
          window.location.href = "/profile";
        }
      })
      .catch((error) => {
        console.log(error);
        setText(error.response.data.detail);
        setModal(true);
      })
      .finally(() => {
        setIsBlocked(false);
      });
  }

  return (
    <div className={styles.registration_container}>
      <ModalWindow
        setShown={() => setModal(false)}
        isShown={isModal}
        text={text}
      />
      <h1 className={styles.title} style={{ textAlign: "center" }}>
        {t("reg.1")}
      </h1>
      <div className={styles.registration_active_section}>
        <input
          autocomplete="off"
          onInput={handleInputEmail}
          value={email}
          className={`${styles.input} ${
            isValidEmail ? styles.valid : styles.invalid
          }`}
          placeholder="Email"
          type="text"
        />
        {!isValidEmail && (
          <p className={styles.error}>{t("reg.email_error")}</p>
        )}
        <p className={styles.text}>{t("reg.2")}</p>

        <input
          autocomplete="off"
          onInput={(e) => setUsername(e.target.value)}
          value={username}
          className={`${styles.input} ${
            username.length > 0 ? styles.valid : styles.invalid
          }`}
          placeholder={t("edit.12")}
          type="text"
        />
        <input
          autocomplete="off"
          onInput={(e) => setName(e.target.value)}
          value={name}
          className={`${styles.input} ${
            name.length > 0 ? styles.valid : styles.invalid
          }`}
          placeholder={t("reg.3")}
          type="text"
        />
        <input
          autocomplete="off"
          onInput={(e) => setSurname(e.target.value)}
          value={surname}
          className={`${styles.input} ${
            surname.length > 0 ? styles.valid : styles.invalid
          }`}
          placeholder={t("reg.4")}
          type="text"
        />
        <input
          autocomplete="off"
          onInput={handleInputWallet}
          value={walletAddress}
          className={`${styles.input} ${
            isValidWallet ? styles.valid : styles.invalid
          }`}
          placeholder={t("reg.20")}
          type="text"
        />
        {!isValidWallet && (
          <p className={styles.error}>{t("reg.wallet_error")}</p>
        )}
        <input
          autocomplete="off"
          maxLength="20"
          onInput={(e) => setPassword(e.target.value)}
          value={password}
          className={`${styles.input} ${styles.valid}`}
          placeholder={t("reg.7")}
          type="password"
        />
        <p className={styles.text}>{t("reg.8")}</p>
        <input
          autocomplete="off"
          onInput={(e) => setReferalLink(e.target.value)}
          value={referalLink}
          className={`${styles.input} ${styles.valid}`}
          placeholder={t("reg.10")}
          type="text"
        />
        <label className={styles.checkBox}>
          <input
            onChange={handleInputAgreement}
            value={checkbox}
            className={styles.checkBox_input}
            type="checkbox"
          />
          <span className={styles.fake_accept}></span>
          <span style={{ color: "#fff", marginLeft: "20px" }}>
            Agree terms
            <a target="_blank" href="/agreement" className={styles.agree_link}>
              {t("reg.11")}
            </a>
          </span>
        </label>

        <div className={styles.button_container}>
          <button
            className={styles.reg_button}
            disabled={isBlocked}
            style={{ opacity: isBlocked ? 0.5 : 1 }}
            onClick={() => Register()}
          >
            <FontAwesomeIcon icon={faUserPlus} /> {t("reg.12")}
          </button>
        </div>
        <div style={{ marginTop: "10px" }}>
          <span style={{ color: "#fff", marginRight: "10px" }}>
            {t("auth.link2")}
          </span>
          <Link className={styles.auth_link} to="/authorization">
            {t("auth.4")}
          </Link>
        </div>
      </div>
    </div>
  );
}
